// Define the `embarkApp` module
var embarkApp = angular.module('embarkApp', ['angularMoment']);
embarkApp.constant('moment', require('moment-timezone'));

// Define the `EmbarkController` controller on the `embarkApp` module
embarkApp.controller('EmbarkController', ['$scope', '$http','moment', function EmbarkController($scope, $http, moment) {
    $scope.name = "Hello Word";
    $scope.embarks = [];
    $scope.showPageList = true;
    $scope.showPageDetail = false;
    moment.locale('es');

    $scope.loadEmbark = function(){
        $http({
            method : "GET",
            url : "get_list_embark"
        }).then(function mySuccess(response) {
            $scope.embarks = response.data;
        }, function myError(response) {
            console.log("response failure");
        });
    }

    $scope.getFullNameDate = function (date){
        var dateName =  moment(date).format('dddd DD [de] MMMM [del] YYYY');
        dateName = dateName.charAt(0).toUpperCase()+dateName.slice(1);
        dateName = dateName+" ("+moment(date).fromNow()+")";
        return dateName;
    }

    $scope.openPageDetail = function(){
        $scope.showPageList = false;
        $scope.showPageDetail = true;
    }

    $scope.openPageList = function(){
        $scope.showPageList = true;
        $scope.showPageDetail = false;
    }
}]);

// Define the `EmbarkController` controller on the `embarkApp` module
embarkApp.controller('DetailEmbarkController', ['$scope', '$http', '$window', function EmbarkDetailController($scope, $http, $window) {

    $scope.embarkData = {};

    $scope.confirmChangeToSendStatusEmbark = function(id){
        console.log("Confirmar cambio de estatus del embarque");
        var result = $window.confirm("¿Confirma que el embarque esta listo para su envio? Nota: asegurese de haber asignado precios de manera correcta");
        if(result){
            $http({
                method : "POST",
                url : "../enviar_embarque",
                data: {id: id}
            }).then(function mySuccess(response) {
                $window.location.reload();
            }, function myError(response) {
                $window.alert("Ocurrio un error al procesar la solicitud intente de nuevo más tarde");
            });
        }
    }

    $scope.saveInformationNumberEmbark = function(id){
        console.log("Guardar datos...");
        jquery('#exampleModal').modal('hide');
        $http({
            method : "POST",
            url : "../guardar_numeros",
            data: {id: id,guide_number: $scope.embarkData.guide_number,invoice_number: $scope.embarkData.invoice_number}
        }).then(function mySuccess(response) {
            $window.location.reload();
        }, function myError(response) {
            $window.alert("Ocurrio un error al procesar la solicitud intente de nuevo más tarde");
        });
    }

}]);

embarkApp.controller('AssignPriceEmbarkController', ['$scope', '$http', '$window', function EmbarkDetailController($scope, $http, $window) {
    $scope.name = "Hello Word";

    $scope.getTotal = function(){
        var total = 0;
        angular.forEach($scope.embark.list_species_objects,function(specie){
            specie.total = (specie.price * specie.weight).toFixed(2);
            total = total + Number(specie.total);
        });
        return total.toFixed(2);
    }

    $scope.savePrices = function(){
        console.log("save price");
        $http({
            method : "POST",
            url : "../guardar_precio",
            data: {
                id: $scope.embark.id,
                price_list: $scope.embark.list_species_objects
            }
        }).then(function mySuccess(response) {
            console.log("response success");
            //$window.alert("Lista de precios actualizada de manera exitosamente");
            jquery('#successModal').modal('show');
        }, function myError(response) {
            console.log("response failure");
            console.log(response);
            $window.alert("Ocurrio un problema en el servidor, intente nuevamente si el problema persiste contacte al administrador");
        });
    }

    $scope.refreshPage = function(){
        $window.location.href = "../show/"+$scope.embark.id;
    }
}]);

/**
embarkApp.run(function(amMoment) {
    amMoment.changeLocale('es');
});**/
embarkApp.controller('ClientController', ['$scope', '$http', '$window', function EmbarkDetailController($scope, $http, $window) {

    $scope.deleteMessage = function (id){

        var result = $window.confirm("¿Confirma que desea eliminar el cliente?");
        if(result){
            $http({
                method : "DELETE",
                url : "client/"+id
            }).then(function mySuccess(response) {

                if(response.data.status){
                    $window.alert("El registro fue eliminado exitosamente");
                    $window.location.reload();
                }
                else{
                    $window.alert("El registro no pudo ser eliminado intente nuevamente");
                }
            }, function myError(response) {
                $window.alert("Ocurrio un error al procesar la solicitud intente de nuevo más tarde");
            });
        }
    }

}]);


embarkApp.controller('CustomProductController', ['$scope', '$http', '$window', function EmbarkDetailController($scope, $http, $window) {

    $scope.filter = 0;

    $scope.deleteMessage = function (id){

        var result = $window.confirm("¿Confirma que desea eliminar el producto?");
        if(result){
            $http({
                method : "DELETE",
                url : "custom_product/"+id
            }).then(function mySuccess(response) {

                if(response.data.status){
                    $window.alert("El registro fue eliminado exitosamente");
                    $window.location.reload();
                }
                else{
                    $window.alert("El registro no pudo ser eliminado intente nuevamente");
                }
            }, function myError(response) {
                $window.alert("Ocurrio un error al procesar la solicitud intente de nuevo más tarde");
            });
        }
    }

    $scope.filterProducts = function(){
        if($scope.filter!=null && $scope.filter>0){
            $window.location.href = "/custom_product?specie_id="+$scope.filter;
        }
    }

}]);